import {Component, toNative, Vue} from "vue-facing-decorator";

@Component({
  template:`
  <div>
    <div class="img">
      <img src="./img/space-cat.png" alt="космокот"/>
    </div>
    <div class="text">
      Привет! Я Саша, фронтенд-разработчик в финтех-компании.<br>
      С 2012 года занимаюсь вёрсткой сайтов (html, css, немного jquery).<br>
      В 2019 году стал фронтенд-разработчиком (typescript, angular, vue).
      <br><br>
      Сейчас сайт находится в стадии разработки, поэтому здесь нет ничего интересного.
    </div>

    <h3>Навыки</h3>
    <div class="skills">
      <div v-for="skill in skills" class="skill">
        <div>{{ skill.name }}</div>
        <div class="skill__level" :style="{ width: 'calc(' + skill.level + '% - 128px)' }"></div>
      </div>
    </div>

    <h3>Социальные профили</h3>
    <div class="social">
      <a v-for="profile in profiles" :class="['social__profile', profile.name]" :href="profile.url" target="_blank">
        <div>{{ profile.description }}</div>
      </a>
    </div>
  </div>`
})
class AboutPage extends Vue {

  /** Навыки */
  readonly skills: Skill[] = [
    {name: "HTML", level: 90},
    {name: "CSS/SCSS", level: 80},
    {name: "TypeScript", level: 65},
    {name: "Vue", level: 60},
    {name: "Angular", level: 55}
  ];

  /** Социальные профили */
  readonly profiles: SocialProfile[] = [
    {name: "github", description: "Github", url: "https://github.com/space-bro"},
    {name: "gitverse", description: "Gitverse", url: "https://gitverse.ru/bro"},
    {name: "leetcode", description: "LeetCode", url: "https://leetcode.com/user6077MW/"},
    {name: "codewars", description: "Codewars", url: "https://www.codewars.com/users/spacebro"},
  ];
}

interface Skill {
  name: string;
  level: number;
}

interface SocialProfile {
  name: string;
  description: string;
  url: string;
}
export default toNative(AboutPage);