import {Component, toNative, Vue} from "vue-facing-decorator";

@Component({
  template:`
    <button class="theme-toggle" :title="title" @click="toggleTheme">
      <svg class="sun-and-moon" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"  fill="currentColor">
        <circle class="sun" cx="12" cy="12" r="6" mask="url(#moon-mask)" fill="currentColor" />
        <g class="sun-beams" stroke="currentColor">
          <line x1="12" y1="1" x2="12" y2="3" />
          <line x1="12" y1="21" x2="12" y2="23" />
          <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
          <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
          <line x1="1" y1="12" x2="3" y2="12" />
          <line x1="21" y1="12" x2="23" y2="12" />
          <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
          <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
        </g>
        <mask class="moon" id="moon-mask">
          <rect x="0" y="0" width="100%" height="100%" fill="white" />
          <circle cx="24" cy="10" r="6" fill="black" />
        </mask>
      </svg>
    </button>
  `
})
class ThemeSwitcher extends Vue {

  /** Наименование ключа для хранилища */
  private readonly storageKey = "theme";
  /** Выбранная тема */
  theme!: Theme;

  created(): void {
    this.theme = this.getColorPreference();
    this.setTheme();
  }

  /** Переключает тему */
  toggleTheme(): void {
    document.body.classList.remove("theme-" + this.theme);
    this.theme = this.theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
    this.setTheme();
  }

  /** Подсказка */
  get title(): string {
    return this.theme === Theme.LIGHT? "Включить тёмную тему" : "Включить светлую тему";
  }

  /** Устанавливает выбранную тему */
  private setTheme(): void {
    localStorage.setItem(this.storageKey, this.theme);
    document.body.classList.add("theme-" + this.theme);
  }

  /** Возвращает выбранную тему */
  private getColorPreference(): Theme {
    const theme = localStorage.getItem(this.storageKey);
    if (theme) {
      return theme as Theme;
    }
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? Theme.DARK : Theme.LIGHT;
  }
}

/** Тема */
enum Theme {
  LIGHT = "light",
  DARK = "dark"
}

export default toNative(ThemeSwitcher);