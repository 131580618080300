import { Component, Vue, toNative } from "vue-facing-decorator";
import "./assets/scss/style.scss";

@Component({
    name: "app",
    template: `
     <div class="main-content">
       <theme-switcher></theme-switcher>
        <router-view/>
    </div>`
})
class App extends Vue {
}

export default toNative(App);